import { useTranslation } from '@sm360/hooks'
import { Button, Tag } from '@sm360/ui-library'
import clsx from 'clsx'
import { Record } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import { ListingContext } from '../../configs/listing-view/state.manager'
import { FILTER_OPTIONS_REMOVED_FROM_URL } from '../../constants/filterOptions'
import { changeUrl } from '../../utils/handle-url.utils'

const FiltersReset = ({ extraClasses }) => {
    const {
        state: {
            filterMetaData,
            vehicleCriteria,
            dealerInfos: { lang },
            hubName,
        },
        addVehicleCriteria,
        getEmptyCriteria,
        dispatch,
    } = React.useContext(ListingContext)

    const { t } = useTranslation()

    const baseClass = 'il-filtersReset'
    const classNames = clsx(baseClass, extraClasses)

    // Some of the filters with input box value
    const inputFilters = ['minPrice', 'maxPrice', 'text', 'minOdometer', 'maxOdometer', 'minYear', 'maxYear']
    const handleClick = (attribute, code, metadatas) => {
        const selectedIndex = metadatas.findIndex((metadata) => metadata.code === code)
        if (Record.isRecord(metadatas[selectedIndex])) {
            metadatas[selectedIndex] = metadatas[selectedIndex].set('isSelected', !metadatas[selectedIndex].isSelected)
        } else {
            metadatas[selectedIndex].isSelected = !metadatas[selectedIndex].isSelected
        }
        const criteria = addVehicleCriteria(vehicleCriteria, metadatas, attribute)
        changeUrl(criteria, filterMetaData, lang, hubName)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
    }

    const handleClickForInputValue = (name, value) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { [name]: value })
        changeUrl(criteria, filterMetaData, lang, hubName)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
    }

    // Clear filter button action
    const handleClearClick = () => {
        const newCriteria = getEmptyCriteria(vehicleCriteria)
        changeUrl({}, [], lang, hubName, true)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: newCriteria })
        dispatch({ type: 'clearFilters', clearFilters: true })
    }

    // Checks which sidebar filters are actually applied
    const appliedFilters =
        filterMetaData &&
        filterMetaData.length > 0 &&
        filterMetaData.filter(
            ({ name }) =>
                Object.keys(vehicleCriteria).includes(name) &&
                !FILTER_OPTIONS_REMOVED_FROM_URL.includes(name) &&
                vehicleCriteria[name].length > 0
        )

    const appliedInputFilters = Object.keys(vehicleCriteria).filter(
        (key) => inputFilters.includes(key) && vehicleCriteria[key].length > 0
    )

    const appliedPagination = Object.keys(vehicleCriteria).filter((key) => key === 'page' && vehicleCriteria.page !== '')

    // TODO: effacer apres recuperer la vraie data
    const skippedFilters = [
        'priceRangeAll',
        'odometerBuckets',
        'odometerRange',
        'priceBuckets',
        'yearRange',
        'odometerRangeAll',
        'priceRange',
        'inventoryId',
        'yearRangeAll',
        'makeIdAndModelId',
    ]

    if ((!appliedFilters || appliedFilters.length === 0) && appliedInputFilters.length === 0 && appliedPagination.length === 0)
        return null

    return (
        <div className={classNames}>
            {filterMetaData.map(
                ({ metadatas, name }) =>
                    !skippedFilters.includes(name) &&
                    metadatas?.map(
                        (metadata, key) =>
                            // TODO: activer la condition une fois que la logique de filtres fonctionne correctement
                            metadata.isSelected && (
                                <Tag
                                    key={key}
                                    className={`${baseClass}__tag`}
                                    label={`${t(`filters.reset.${name}`)}: ${metadata.label}`}
                                    iconPosition='right'
                                    icon='icon-cross'
                                    onClick={() => handleClick(name, metadata.code, metadatas)}
                                />
                            )
                    )
            )}

            {/* For single input value - ex:  price, odometer, search text */}
            {Object.entries(vehicleCriteria).map(([key, value]) => {
                return (
                    (inputFilters.includes(key) || key === 'page') &&
                    value !== '' && (
                        <Tag
                            key={key}
                            className={`${baseClass}__tag`}
                            label={`${t(`filters.reset.${key}`)}: ${value}`}
                            iconPosition='right'
                            icon='icon-cross'
                            onClick={() => handleClickForInputValue(key, '')}
                        />
                    )
                )
            })}
            <Button label={t('filters.reset.clear')} className={`${baseClass}__clear`} onClick={handleClearClick} />
        </div>
    )
}

FiltersReset.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

FiltersReset.defaultProps = {
    extraClasses: '',
}

export default FiltersReset
