import React from 'react'
import clsx from 'clsx'
import { Record } from 'immutable'
import PropTypes from 'prop-types'
import { useTranslation } from '@sm360/hooks'
import { ListingContext } from '../../configs/listing-view/state.manager'
import FilterOption from '../filter-option/FilterOption'
import FilterSection from '../filter-section/FilterSection'
import { changeUrl } from '../../utils/handle-url.utils'
import ga4Trigger from '../../datalayer/analytics.service'
import dataLayerTags, { FILTER_MAP } from '../../constants/dataLayerTags'
import { getPathname, isArray } from '../../utils/helper.utils'

// name the filters that allow to display count
const filtersAllowCount = ['makeId', 'modelId']

const FilterSections = ({ extraClasses }) => {
    const {
        state: { filterMetaData, vehicleCriteria, hubName, vehicles, filters, isHumberview },
        addVehicleCriteria,
        dispatch,
    } = React.useContext(ListingContext)
    const {
        i18n: { language },
    } = useTranslation()
    const baseClass = 'il-filterSections'
    const classNames = clsx(baseClass, extraClasses)

    const triggerAnalytics = (attribute, label, isSelected) => {
        ga4Trigger(dataLayerTags.LAYER_NAVIGATION__FILTERS(getPathname(), FILTER_MAP(attribute)))
        const gaOptions = dataLayerTags.FILTER_OPTIONS(attribute, label)
        if (gaOptions && !isSelected) ga4Trigger(gaOptions)
    }

    const triggerRangeAnalytics = (attribute, value) => {
        ga4Trigger(dataLayerTags.LAYER_NAVIGATION__FILTERS(getPathname(), FILTER_MAP(attribute)))
        const gaOptions = dataLayerTags.FILTER_OPTIONS(attribute, value)
        if (gaOptions) ga4Trigger(gaOptions)
    }

    const handleChange = (attribute, { code, label, isSelected }, metadatas) => {
        const selectedIndex = metadatas.findIndex((metadata) => metadata.code === code)
        if (Record.isRecord(metadatas[selectedIndex])) {
            metadatas[selectedIndex] = metadatas[selectedIndex].set('isSelected', !metadatas[selectedIndex].isSelected)
        } else {
            metadatas[selectedIndex].isSelected = !metadatas[selectedIndex].isSelected
        }
        const criteria = addVehicleCriteria(vehicleCriteria, metadatas, attribute, true)
        changeUrl(criteria, filterMetaData, language, hubName)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
        triggerAnalytics(attribute, label, isSelected)
    }

    const handleInputRange = (name, value, attribute) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { [name]: value }, null, true)
        changeUrl(criteria, filterMetaData, language, hubName)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
        triggerRangeAnalytics(attribute, value)
    }
    const isSectionOpen = (name) => Object.keys(vehicleCriteria).includes(name)

    /**
     * Function to check if itemId (modelId/trimId) is relevant to selected parent item (make or model)
     * @param {*} parentItem // For model -> make, trim -> model
     * @param {*} parentItemToValidate // For modelId -> makeIdAndModelId, for trimId -> modelId
     * @param {*} currentItemId // either modelId or trimId for now
     * @returns boolen to indicate if itemId is relevant to selected parent item or not
     */
    const isItemRelevantToParentItem = (parentItem, parentItemToValidate, currentItemId) => {
        const isParentItemSelected = Object.keys(vehicleCriteria).includes(parentItem) && vehicleCriteria?.[parentItem].length > 0
        const selectedParentItems = isParentItemSelected ? vehicleCriteria?.[parentItem] : []

        if (!isParentItemSelected || !isArray(filters?.[parentItemToValidate])) return true

        return filters?.[parentItemToValidate]
            .filter(({ key }) => selectedParentItems.includes(key)) // find selected parentId
            .map(({ includedItemsValueKeys }) => includedItemsValueKeys) // keep only array of child
            .flat() // flatten final array with only child models
            .includes(currentItemId) // check if curentId is in final array of available parents includedItems
    }

    const isModel = (name) => {
        return name === 'modelId'
    }

    const isTrim = (name) => {
        return name === 'trimId'
    }

    return (
        <div className={classNames}>
            {filterMetaData &&
                filterMetaData.length > 0 &&
                filterMetaData
                    ?.filter(({ label }) => label !== undefined)
                    .map(({ name, label, metadatas }) => (
                        <FilterSection key={name} label={label[language]} isOpen={isSectionOpen(name)}>
                            {metadatas &&
                                metadatas.length > 0 &&
                                metadatas.map((metadata) => {
                                    const { code, count, type, label, isSelected, min, max } = metadata
                                    const displayCount = (!isHumberview && count > 0) || filtersAllowCount.includes(name)

                                    // don't show unselected models if a make is selected
                                    if (
                                        isModel(name) &&
                                        !isSelected &&
                                        !isItemRelevantToParentItem('makeId', 'makeIdAndModelId', code)
                                    ) {
                                        return null
                                    }
                                    // Show only relevant trim to the selected model
                                    if (isTrim(name) && !isSelected && !isItemRelevantToParentItem('modelId', 'modelId', code)) {
                                        return null
                                    }

                                    return (
                                        <FilterOption
                                            key={`${code} | ${label}`}
                                            // TODO: Remove Number to string conversion once get the final fitler metadata
                                            label={typeof label === 'string' ? label : label.toString()}
                                            count={count}
                                            type={type}
                                            handleChange={() => handleChange(name, metadata, metadatas)}
                                            isSelected={isSelected}
                                            handleInputRange={(inputName, value) => handleInputRange(inputName, value, name)}
                                            vehicleCriteria={vehicleCriteria}
                                            min={parseInt(min)}
                                            max={parseInt(max)}
                                            filterName={name}
                                            displayCount={displayCount}
                                        />
                                    )
                                })}
                        </FilterSection>
                    ))}
        </div>
    )
}

FilterSections.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

FilterSections.defaultProps = {
    extraClasses: '',
}

export default FilterSections
