import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useTranslation } from '@sm360/hooks'
import { DisclaimerSymbols } from '@sm360/components'
import formatNumber from '../../../../../../utils/format-number.utils'

const Price = ({
    sellingPrice,
    retailDetailsTotal,
    showTitle,
    showRetailsTotal,
    displayDisclaimerSymbols,
    priceRounding,
    extraClasses,
}) => {
    const { t } = useTranslation()

    const baseClass = 'vc-price'
    const classNames = clsx(baseClass, extraClasses)

    if (!sellingPrice && !retailDetailsTotal) return null

    return (
        <div className={classNames}>
            {showTitle && <p className={`${baseClass}__title`}>{t('paymentOptions.sellingPrice')}</p>}
            <div className={`${baseClass}__wrapper`}>
                {retailDetailsTotal !== sellingPrice && showRetailsTotal && (
                    <del className={`${baseClass}__retailDetails`}>{formatNumber(retailDetailsTotal, priceRounding)}</del>
                )}
                <span className={`${baseClass}__sellingPrice`}>{formatNumber(sellingPrice, priceRounding)}</span>
                {displayDisclaimerSymbols && <DisclaimerSymbols extraClasses={`${baseClass}__disclaimerSymbols`} />}
            </div>
        </div>
    )
}

Price.propTypes = {
    /** Selling Price */
    sellingPrice: PropTypes.number,
    /** Retails Details Total */
    retailDetailsTotal: PropTypes.number,
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Show Selling Price Title */
    showTitle: PropTypes.bool,
    /** Show Retails Total */
    showRetailsTotal: PropTypes.bool,
    /** Flag to show or hide disclaimer symbols */
    displayDisclaimerSymbols: PropTypes.bool,
    /** Rounding option for prices */
    priceRounding: PropTypes.string,
}

Price.defaultProps = {
    extraClasses: '',
    sellingPrice: 0,
    retailDetailsTotal: 0,
    showTitle: true,
    showRetailsTotal: true,
    displayDisclaimerSymbols: false,
    priceRounding: 'ROUND_UP',
}

export default Price
