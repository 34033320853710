import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import FeesDisclaimer from '../common/fees-disclaimer/FeesDisclaimer'
import TaxesDisclaimer from '../common/taxes-disclaimer/TaxesDisclaimer'
import Incentives from '../common/incentives/Incentives'
import Price from '../common/price/Price'
import VehicleRebates from '../../vehicle-rebates/VehicleRebates'

const CashCard = ({
    cashPurchase,
    extraClasses,
    showIncentives,
    showRetailsTotal,
    disclaimer,
    showVehicleRebate,
    displayDisclaimerSymbols,
    priceRounding,
}) => {
    const { sellingPrice, retailDetails, taxesDisclaimer, incentives } = cashPurchase

    const baseClass = 'vc-cashCard'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <Price
                extraClasses={`${baseClass}__price`}
                sellingPrice={sellingPrice}
                retailDetailsTotal={retailDetails?.total}
                showTitle={false}
                showRetailsTotal={showRetailsTotal}
                displayDisclaimerSymbols={displayDisclaimerSymbols}
                priceRounding={priceRounding}
            />
            {showIncentives && (
                <Incentives extraClasses={`${baseClass}__incentives`} incentives={incentives} priceRounding={priceRounding} />
            )}
            {showVehicleRebate && <VehicleRebates extraClasses={`${baseClass}__rebates`} priceRounding={priceRounding} />}
            <TaxesDisclaimer extraClasses={`${baseClass}__taxesDisclaimer`} taxesDisclaimer={taxesDisclaimer} />
            <FeesDisclaimer extraClasses={`${baseClass}__feesDisclaimer`} feesDisclaimer={disclaimer} />
        </div>
    )
}

CashCard.propTypes = {
    /** Payment Infos */
    cashPurchase: PropTypes.object,
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Show Incentives */
    showIncentives: PropTypes.bool,
    /** Show Retails Total */
    showRetailsTotal: PropTypes.bool,
    /** Disclaimer */
    disclaimer: PropTypes.string,
    /** Flag to show or hide Vehicle Rebate */
    showVehicleRebate: PropTypes.bool,
    /** Flag to show or hide disclaimer symbols */
    displayDisclaimerSymbols: PropTypes.bool,
    /** Rounding option for prices */
    priceRounding: PropTypes.string,
}

CashCard.defaultProps = {
    extraClasses: '',
    cashPurchase: {},
    showIncentives: true,
    showRetailsTotal: true,
    disclaimer: '',
    showVehicleRebate: false,
    displayDisclaimerSymbols: false,
    priceRounding: 'ROUND_UP',
}

export default CashCard
