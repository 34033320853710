import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import Cashdown from '../common/cashdown/Cashdown'
import PaymentFrequency from '../common/payment-frequency/PaymentFrequency'
import PercentRate from '../common/percent-rate/PercentRate'
import FeesDisclaimer from '../common/fees-disclaimer/FeesDisclaimer'
import TaxesDisclaimer from '../common/taxes-disclaimer/TaxesDisclaimer'
import Incentives from '../common/incentives/Incentives'
import Term from '../common/term/Term'
import TotalCreditCost from '../common/total-credit-cost/TotalCreditCost'
import VehicleRebates from '../../vehicle-rebates/VehicleRebates'

const LeaseCard = ({
    lease,
    disclaimer,
    lang,
    showVehicleRebate,
    displayDisclaimerSymbols,
    extraClasses,
    displayTotalCreditCost,
    priceRounding,
}) => {
    const { term, paymentFrequency, cashDown, incentives, taxesDisclaimer } = lease

    const baseClass = 'vc-leaseCard'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <div className={`${baseClass}__price`}>
                <div className={`${baseClass}__frequencyTerm`}>
                    <PaymentFrequency
                        termPayment={term.payment}
                        frequency={paymentFrequency}
                        displayDisclaimerSymbols={displayDisclaimerSymbols}
                        priceRounding={priceRounding}
                    />
                    <Term term={term?.term} />
                </div>
                <div className={`${baseClass}__rateCashDown`}>
                    <PercentRate rate={term.apr} term={term.term} />
                    <Cashdown cashDown={cashDown} priceRounding={priceRounding} />
                </div>
            </div>
            {displayTotalCreditCost && (
                <TotalCreditCost
                    extraClasses={`${baseClass}__creditCost`}
                    totalCreditCost={term?.aprDetails?.totalCreditCost}
                    lang={lang}
                    priceRounding={priceRounding}
                />
            )}
            {showVehicleRebate && <VehicleRebates extraClasses={`${baseClass}__rebates`} priceRounding={priceRounding} />}
            <Incentives extraClasses={`${baseClass}__incentives`} incentives={incentives} priceRounding={priceRounding} />
            <TaxesDisclaimer extraClasses={`${baseClass}__taxesDisclaimer`} taxesDisclaimer={taxesDisclaimer} />
            <FeesDisclaimer extraClasses={`${baseClass}__feesDisclaimer`} feesDisclaimer={disclaimer} />
        </div>
    )
}

LeaseCard.propTypes = {
    /** Lease object */
    lease: PropTypes.object,
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Disclaimer */
    disclaimer: PropTypes.string,
    /** Selected language */
    lang: PropTypes.string,
    /** Flag to show or hide Vehicle Rebate */
    showVehicleRebate: PropTypes.bool,
    /** Flag to show or hide disclaimer symbols */
    displayDisclaimerSymbols: PropTypes.bool,
    /** Display the total cost of credit amount */
    displayTotalCreditCost: PropTypes.bool,
    /** Rounding option for prices */
    priceRounding: PropTypes.string,
}

LeaseCard.defaultProps = {
    lease: {},
    extraClasses: '',
    disclaimer: '',
    lang: 'en',
    showVehicleRebate: false,
    displayDisclaimerSymbols: false,
    displayTotalCreditCost: false,
    priceRounding: 'ROUND_UP',
}

export default LeaseCard
